// facebook conversions api
export function sendFacebookConversionApiEvent(event: any): Promise<Response> {
  const apiPathAnalytics = '/umbraco/surface/analyticssurface/';

  const fetchBaseConfig: RequestInit = {
    credentials: 'same-origin',
    headers: {
      'content-type': 'application/json',
      accept: 'application/json'
    }
  };

  const path = `${apiPathAnalytics}SendEvent`;
  const data = {
    data: [
      {
        event_name: event.event_name,
        event_id: event.event_id,
        custom_data: {
          content_name: event.content_name,
          content_ids: event.content_ids,
          content_type: event.content_type,
          value: event.value ? parseFloat(event.value) : 0,
          currency: event.currency,
          contents: event.contents,
          search_string: event.search_string
        }
      }
    ]
  };

  const config = {
    ...fetchBaseConfig,
    body: JSON.stringify(data),
    method: 'POST'
  };

  return fetch(path, config).then((response) => {
    return response.json();
  });
}
