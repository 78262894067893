/* global global */

import { showButtonDisabledWithSpinner } from '../helpers/showDisabledWithSpinner';
import popup from '../utilities/popup-alert';
import {
  addGA4BasketRowEvent,
  toListId,
  calculateLinePrice
} from '../helpers/ga4';

function trackAddToBasket(addedItemCatalogueRef) {
  $.ajax({
    url: '/umbraco/surface/BasketSurface/BasketPageInner',
    type: 'GET',
    contentType: 'application/json',
    error: function (data) {
      console.log('Error updating add to basket to monetate. error: ', data);
    },
    success: function (data) {
      if (data.hasRows) {
        window.monetateQ = window.monetateQ || [];
        window.monetateQ.push(['setPageType', 'main']);
        data.rows.forEach((element) => {
          window.monetateQ.push([
            'addCartRows',
            [
              {
                productId: element.catalogueRef,
                sku: element.sku,
                quantity: element.quantity,
                unitPrice: element.priceExcVat,
                currency: 'GBP'
              }
            ]
          ]);

          if (element.catalogueRef === addedItemCatalogueRef) {
            addGA4BasketRowEvent(
              'add_to_cart',
              element,
              {
                item_list_id: toListId(document.title),
                item_list_name: document.title,
                value:
                  element.linePriceIncVat !== 0
                    ? element.linePriceIncVat
                    : calculateLinePrice(element.priceIncVat, element.quantity),
                valueExcVat:
                  element.linePriceExVat !== 0
                    ? element.linePriceExVat
                    : calculateLinePrice(element.priceExcVat, element.quantity)
              },
              element.quantity
            );
          }
        });
        window.monetateQ.push(['trackData']);
      }
    }
  });
}

window.Basket = {
  // adding basket items
  onAddSuccess(data) {
    if (data.redirectUrl) {
      // if the server returned a JSON object containing a redirect url redirect (probably login)
      window.location.href = data.redirectUrl;
    }

    const response = JSON.parse(data.responseText);

    $('.header-icon__badge').text(response.BasketItemCount);

    if (!response.Success) {
      popup.show(response.Message, popup.types.negative, popup.icons.negative);
    } else if (response.Warning) {
      popup.show(response.Message, popup.types.warning, popup.icons.negative);
    } else {
      popup.show(response.Message, popup.types.positive, popup.icons.positive);
      $(this).find('.btn').text('Added to basket');
      trackAddToBasket(data.responseJSON?.AddedItemCatalogueRef);
    }
  },

  onAddError() {
    popup.show(
      'There was a problem adding this item to your basket',
      popup.types.negative,
      popup.icons.negative
    );
  },

  updateMiniBasketItemCount() {
    $('.header-icon__badge').load(
      '/umbraco/surface/basketsurface/getbasketitemcount'
    );
  }
};

// Send to fms modal
global.BasketSendToFmsModal = {
  onBegin: function () {
    showButtonDisabledWithSpinner();
  },
  onSuccess: (data) => {
    //get the correct form id from the partial html
    var el = $('<div></div>').html(data);
    var form = $('form', el);
    if (form.length === 0) return;
    var formId = form.attr('id');

    //render the captcha if required
    window.reCAPTCHA.render(formId);

    const choice = $('.js-webfms-system-choice').val();
    if (choice === 'other') {
      $('.js-webfms-other-systems').removeClass('hidden');
    } else {
      $('.js-webfms-other-systems').addClass('hidden');
    }
  }
};
