/**
 * These are scripts that are dynamically imported
 */
(async () => {
  /**
   * IE11 Polyfills
   */
  const isIE11 = document.querySelector('.ie-11');

  if (isIE11) {
    import(
      /* webpackPrefetch: false, webpackChunkName: "elementClosestPolyfill" */ 'element-closest-polyfill'
    );
  }

  /**
   * SVG
   */
  const { default: svg4everybody } = await import(
    /*webpackPreload: false, webpackChunkName: "svg4everybody" */ 'svg4everybody'
  );

  svg4everybody();

  /**
   * Domtools
   */
  /**
   * ProductPod
   */
  if ($('.product-pod__image').length > 0) {
    import(
      /* webpackPrefetch: false, webpackChunkName: "productPod" */ './domtools/productPod'
    );
  }
  /**
   * Recommendation Gallery
   */
  if ($('.recommendation-gallery__items').length > 0) {
    import(
      /* webpackPrefetch: false, webpackChunkName: "recommendationGallery" */ './domtools/recommendationGallery'
    );
  }

  /**
   * PLP
   */
  if ($('#product-list-container').length > 0) {
    import(
      /* webpackPrefetch: false, webpackChunkName: "productList" */ './domtools/productList'
    );
  }

  /**
   * Filters
   */
  if (
    $('.js-prod-filter').length > 0 ||
    $('.js-list-control-dropdown').length > 0
  ) {
    import(
      /* webpackPrefetch: false, webpackChunkName: "prodNav" */ './domtools/prodNav'
    );
  }

  /**
   * Contact Form - todo being used ?
   */
  if ($('.js-contact-form').length > 0) {
    import(
      /* webpackPrefetch: false, webpackChunkName: "contactForm" */ './domtools/contact-form'
    );
  }

  /**
   * Component Helper: Countdown
   */
  if ($('.js-countdown').length > 0) {
    import(
      /* webpackPrefetch: false, webpackChunkName: "countdown" */ './domtools/countdown'
    );
  }

  /**
   * IP Modal
   */
  if ($('.js-close-ip-modal').length > 0) {
    import(
      /* webpackPrefetch: false, webpackChunkName: "ipModal" */ './domtools/ip-modal'
    );
  }

  /**
   * reCAPTCHA
   */
  if ($('.g-recaptcha').length > 0) {
    import(
      /* webpackPrefetch: false, webpackChunkName: "reCAPTCHA" */ './domtools/reCAPTCHA'
    );
  }

  /**
   * Delivery
   */
  if ($('#delayed-delivery-date').length > 0) {
    import(
      /* webpackPrefetch: false, webpackChunkName: "delivery" */ './domtools/delivery'
    );
  }

  /**
   *  Toggle Target';
   */
  if ($('.js-toggle-target').length > 0) {
    import(
      /* webpackPrefetch: false, webpackChunkName: "toggleTarget" */ './domtools/toggle-target'
    );
  }

  /**
   * Offer Details
   */
  if ($('#offer-details').length > 0) {
    import(
      /* webpackPrefetch: false, webpackChunkName: "offerDetails" */ './domtools/offerDetails'
    );
  }

  /**
   * Registration
   */
  if ($('.js-address-lookup, .js-show-password-button a').length > 0) {
    import(
      /* webpackPrefetch: false, webpackChunkName: "registrationFlow" */ './domtools/registrationFlow'
    );
  }

  /**
   * Overprinting Form
   */
  if ($('.js-overprint-file-upload').length > 0) {
    import(
      /* webpackPrefetch: false, webpackChunkName: "overprintingForm" */ './domtools/overprintingForm'
    );
  }

  /**
   * Documents
   */
  if ($('.copy-doc-page').length > 0) {
    import(
      /* webpackPrefetch: false, webpackChunkName: "documents" */ './domtools/documents'
    );
  }

  /**
   * Prompt Modal
   */
  if ($('[id^=promptModal]').length > 0) {
    import(
      /* webpackPrefetch: false, webpackChunkName: "promptModal" */ './domtools/promptModal'
    );
  }

  /**
   * Reset Password
   */
  const resetPasswordFields = Array.from(
    document.getElementsByClassName('js-password-score-input')
  );

  if (resetPasswordFields.length > 0) {
    import(
      /* webpackPrefetch: false, webpackChunkName: "passwordCheck" */ './domtools/passwordCheck'
    );
  }

  /**
   * Component AccordionList
   */
  if (document.querySelector('.accordion-list')) {
    import(
      /* webpackPrefetch: false, webpackChunkName: "accordion" */ './domtools/accordion'
    );
  }

  if (
    document.querySelector("[data-doctype='pageBasket']") ||
    document.querySelector("[data-parent-doctype='pageCheckout']") ||
    document.querySelector("[data-doctype='pageAccountProfile']")
  ) {
    import(
      /* webpackPrefetch: false, webpackChunkName: "checkout" */ './domtools/checkout'
    );
    import(
      /* webpackPrefetch: false, webpackChunkName: "costcentres" */ './domtools/costcentres'
    );
  }

  import(
    /* webpackPrefetch: false, webpackChunkName: "blog" */ './domtools/blog'
  );
  import(
    /* webpackPrefetch: false, webpackChunkName: "payInvoice" */ './domtools/pay-invoice'
  );
  import(
    /* webpackPrefetch: false, webpackChunkName: "radioList" */ './domtools/radioList'
  );
  import(
    /* webpackPrefetch: false, webpackChunkName: "pcaPredict" */ './domtools/pcaPredict'
  );
  import(
    /* webpackPrefetch: false, webpackChunkName: "account" */ './domtools/account'
  );
  import(
    /* webpackPrefetch: false, webpackChunkName: "webFlow" */ './domtools/webFlow'
  );

  if (document.querySelector('#AddressSearch')) {
    import(
      /* webpackPrefetch: false, webpackChunkName: "googlePlaces" */ './domtools/googlePlaces'
    );
  }

  // /**
  //  *  Unobtrusive Ajax Functions
  //  */
  // import(
  //   /* webpackPrefetch: false, webpackChunkName: "ajaxAccount" */ './unobtrusive-ajax/account'
  // );
  // import(
  //   /* webpackPrefetch: false, webpackChunkName: "ajaxBasket" */ './unobtrusive-ajax/basket'
  // );
  // import(
  //   /* webpackPrefetch: false, webpackChunkName: "ajaxAddressLookup" */ './unobtrusive-ajax/address-lookup'
  // );
  // import(
  //   /* webpackPrefetch: false, webpackChunkName: "ajaxFacets" */ './unobtrusive-ajax/facets'
  // );
  // import(
  //   /* webpackPrefetch: false, webpackChunkName: "ajaxProductList" */ './unobtrusive-ajax/product-list'
  // );
  // import(
  //   /* webpackPrefetch: false, webpackChunkName: "ajaxAddToFavourites" */ './unobtrusive-ajax/order-history'
  // );
  // import(
  //   /* webpackPrefetch: false, webpackChunkName: "ajaxWebFlow" */ './unobtrusive-ajax/webFlow'
  // );
  // import(
  //   /* webpackPrefetch: false, webpackChunkName: "pure360" */ './unobtrusive-ajax/pure360'
  // );
  // import(
  //   /* webpackPrefetch: false, webpackChunkName: "ajaxFavourites" */ './unobtrusive-ajax/favourites'
  // );

  // /**
  //  * SNS forms
  //  */
  // import(
  //   /* webpackPrefetch: false, webpackChunkName: "ajaxNpsFeedbackForm" */ './unobtrusive-ajax/nps-feedback-form'
  // );
  // import(
  //   /* webpackPrefetch: false, webpackChunkName: "ajaxApplyForACredit" */ './unobtrusive-ajax/credit-application-form'
  // );
  // import(
  //   /* webpackPrefetch: false, webpackChunkName: "ajaxLeadGeneration" */ './unobtrusive-ajax/lead-generation-form'
  // );

  /**
   *  React App Bundle
   */
  // import(/* webpackPrefetch: false, webpackChunkName: "reactApps" */ './apps');
})();
