/* eslint-disable no-case-declarations */
/* eslint-disable no-unused-vars */

import { debounce } from './shared';
import { addGA4CheckoutEvent } from '../helpers/ga4';

// Trigger validation on form submit
$('body').on('submit', '.js-form-validate', function () {
  if (!$(this).valid()) {
    $('.form-control').each(function () {
      if ($(this).hasClass('input-validation-error')) {
        $(this).addClass('form-control-danger');
        $(this).closest('.form-group').addClass('has-danger');
      } else {
        $(this).removeClass('form-control-danger');
        $(this).closest('.form-group').removeClass('has-danger');
      }
    });
  }
});

// Trigger checks within every input of the form
$('body').on(
  'keyup',
  '.js-form-validate .form-control',
  debounce(function (event) {
    clearFormGroupValidationClasses();
  }, 150)
);

$('body').on(
  'change',
  '.js-form-validate .form-control',
  debounce(function (event) {
    if ($(this).valid()) {
      $(this).removeClass('form-control-danger');
      $(this).closest('.form-group').removeClass('has-danger');
      if ($(this).hasClass('js-positive-check')) {
        $(this).addClass('form-control-success');
        $(this).closest('.form-group').addClass('has-success');
      }
    } else {
      $(this).addClass('form-control-danger');
      $(this).closest('.form-group').addClass('has-danger');
    }
  }, 100)
);

$(document).ready(function () {
  var validator = $.data($('form')[0], 'validator');
  if (validator) {
    validator.settings.ignore = ':hidden, :disabled, .disable-val';
    setHiddenFormValidation(true);
  }
});

$('input[name=Choice], input[name=SelectedPaymentMethodId]').on(
  'change input',
  function (e) {
    clearValidationMessages();
    if ($(this).hasClass('js-has-dropdown')) {
      setHiddenFormValidation(false);
    } else {
      setHiddenFormValidation(true);
    }
  }
);

// @TODO: Clean this up/Do in smarter way
$('.js-address-submit').on('click', function () {
  var errorLabel = $(this)
    .closest('form')
    .find('#existing-address')
    .closest('.radio-list__option')
    .find('.form-control-feedback');
  if (
    $('#existing-address').prop('checked') &&
    $('#existing-address')
      .parent()
      .find('.radio-option__dropdown input:checked').length == 0
  ) {
    errorLabel.show();
    return false;
  }
  errorLabel.hide();
});

$('.js-address-submit, .js-delivery-options-submit, .js-payment-submit').on(
  'click',
  function (e) {
    e.preventDefault();
    let loaderText = '';
    switch (e.target.id) {
      case 'js-address-submit':
        loaderText =
          document.title.indexOf('International') > -1
            ? 'Loading Payment Options...'
            : 'Loading Delivery Options...';
        break;
      case 'btn-sbmt-delivery-optn':
      case 'js-delivery-options-submit':
        loaderText = 'Loading Payment Options...';
        if (e.target.id === 'js-delivery-options-submit') {
          const selectedDelOption = $('input[name=DeliveryTypeChoice]:checked');
          const selectedDelOptionType = selectedDelOption
            .parent()
            .find('.delivery-option__title')
            .text();
          addGA4CheckoutEvent('add_shipping_info', selectedDelOptionType);
        }
        break;
      case 'js-payment-submit':
        const selectedPaymentMethod = $(
          'input[name=SelectedPaymentMethodId]:checked'
        );
        const selectedPaymentMethodName = selectedPaymentMethod
          .parent()
          .find('.payment-method__title')
          .text();
        addGA4CheckoutEvent('add_payment_info', '', selectedPaymentMethodName);
        loaderText =
          selectedPaymentMethodName === 'Pay On Account'
            ? 'Completing order...'
            : 'Contacting Payment Provider...';

        var custEmailAddress = $('input[name=custEmailId]').val();
        if (custEmailAddress) {
          window._sqzl = window._sqzl || [];
          window._sqzl.push({
            event: 'PrePurchase',
            email: custEmailAddress
          });
        }
        break;
      default:
        loaderText = 'Loading...';
        break;
    }

    $(this).attr('disabled', 'disabled');
    const form = $(this).closest('form')[0];
    form.submit();

    $('#checkout').css('display', 'none');
    $('#loader')
      .addClass('d-block')
      .height('100vh')
      .find('.loader-mask__title')
      .text(loaderText);
  }
);

function setHiddenFormValidation(disable) {
  var newAddressInputs = $('.js-has-dropdown').parent().find('input, select');
  if (disable) {
    newAddressInputs.addClass('disable-val');
    console.log('new address disabled');
    return;
  }
  newAddressInputs.removeClass('disable-val');
  console.log('new address enabled');
}

function clearValidationMessages() {
  clearValidationSummaryMessages();
  clearInputValidationMessages();
  clearFormGroupValidationClasses();
}

function clearValidationSummaryMessages() {
  var container = $('form').find('[data-valmsg-summary=true]'),
    list = container.find('ul');
  if (list && list.length) {
    list.empty();
    container
      .addClass('validation-summary-valid')
      .removeClass('validation-summary-errors');
  }
}

function clearInputValidationMessages() {
  var form = $('form');
  var validator = $('form').validate();
  var errors = form.find('.field-validation-error span');
  errors.each(function () {
    validator.settings.success($(this));
  });
  validator.resetForm();
}

function clearFormGroupValidationClasses() {
  var form = $('form');
  form.find('.has-success').removeClass('has-success');
  form.find('.has-danger').removeClass('has-danger');
}
