$(function () {
  // lazyload placeholder mimics same as plp/search results
  const productImage = $('.product-pod__image');

  productImage.css({
    position: 'absolute',
    backgroundColor: '#f7f7f7',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%'
  });
});
