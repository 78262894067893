/* eslint-disable no-unused-vars */
/* eslint-disable no-redeclare */

/* global window */
/* global $ */

import popup from '../utilities/popup-alert';

window.Favorites = {
  // adding basket items
  onAddSuccess(data, status, xhr) {
    if (data.redirectUrl) {
      // if the server returned a JSON object containing a redirect url redirect (probably login)
      window.location.href = data.redirectUrl;
    }

    const response = JSON.parse(data.responseText);
    $('.header-icon__badge').text(response.BasketItemCount);
    if (!response.Success) {
      popup.show(response.Message, popup.types.negative, popup.icons.negative);
    } else if (response.Warning) {
      popup.show(response.Message, popup.types.warning, popup.icons.negative);
    } else {
      popup.show(response.Message, popup.types.positive, popup.icons.positive);
      $(this).find('.btn').text('Added to basket');
    }
  },
  onAddError(xhr, status, error) {
    popup.show(
      'There was a problem adding this item to your basket',
      popup.types.negative,
      popup.icons.negative
    );
  },
  onRemoveSuccess(data, status, xhr) {
    if (data.redirectUrl) {
      // if the server returned a JSON object containing a redirect url redirect (probably login)
      window.location.href = data.redirectUrl;
    }

    const response = JSON.parse(data.responseText);
    $('.header-icon__badge').text(response.BasketItemCount);
    if (!response.Success) {
      popup.show(response.Message, popup.types.negative, popup.icons.negative);
    } else if (response.Warning) {
      popup.show(response.Message, popup.types.warning, popup.icons.negative);
    } else {
      popup.show(response.Message, popup.types.positive, popup.icons.positive);
      const button = $(this).find('.btn');
      const buttonText = button.html();
      if (response.IsRemoveResponse) {
        button.html(buttonText.replace('Remove from', 'Add to'));
        $(this).find('#isRemove').val(false);
      } else {
        button.html(buttonText.replace('Add to', 'Remove from'));
        $(this).find('#isRemove').val(true);
      }
    }
  },
  onRemoveError(xhr, status, error) {
    popup.show(
      'There was a problem removing this item from your favourites',
      popup.types.negative,
      popup.icons.negative
    );
  }
};
