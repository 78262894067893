import './common.js';

/**
 * These are scripts that run automatically on import
 */
import './domtools/shared';
import './domtools/preChatFormV2';

//Common across all pages
import './domtools/unobtrusiveValidation';
import './domtools/unobtrusiveAjax';
import './domtools/siteHeader';
import './domtools/siteNav';

import './domtools/productPod';
import './domtools/cookieWarning';
import './domtools/dropdowns';
import './domtools/autocomplete';

/**
 *  Unobtrusive Ajax Functions
 */
import './unobtrusive-ajax/account';
import './unobtrusive-ajax/basket';
import './unobtrusive-ajax/address-lookup';
import './unobtrusive-ajax/facets';
import './unobtrusive-ajax/product-list';
import './unobtrusive-ajax/order-history';
import './unobtrusive-ajax/webFlow';
import './unobtrusive-ajax/pure360';
import './unobtrusive-ajax/favourites';

/**
 * SNS forms
 */
import './unobtrusive-ajax/nps-feedback-form';
import './unobtrusive-ajax/credit-application-form';
import './unobtrusive-ajax/lead-generation-form';

import './apps';

/**
 * Analytics
 */
import { sendFacebookConversionApiEvent } from './api/analyticsApi';
import {
  addGA4ProductListingEvent,
  addGA4ProductEvent,
  pushGA4Event,
  addGA4CheckoutCompleteEvent
} from './helpers/ga4';
import { addPreviousOrderProductToCartEvent } from './helpers/addtobasket';

window.Analytics = {
  sendFacebookConversionApiEvent
};

window.ga4 = {
  addGA4ProductListingEvent,
  addGA4ProductEvent,
  pushGA4Event,
  addGA4CheckoutCompleteEvent
};

window.addtobasket = {
  addPreviousOrderProductToCartEvent
};

import './dynamic.js';
