export function showButtonDisabledWithSpinner() {
  const buttons = document.querySelectorAll('.js-btn-disabled');
  buttons.forEach((button) => {
    const loader = button?.querySelector('.loader') as HTMLElement;

    if (button) {
      button.setAttribute('disabled', 'disabled');
      loader.style.display = 'block';
    }
  });
}

export function clearButtonDisabledWithSpinner() {
  const buttons = document.querySelectorAll('.js-btn-disabled');
  buttons.forEach((button) => {
    const loader = button?.querySelector('.loader') as HTMLElement;

    if (button) {
      button.removeAttribute('disabled');
      loader.style.display = 'none';
    }
  });
}

export function showLinkDisabledWithSpinner() {
  const anchors = document.querySelectorAll('.js-link');
  const loader = document.createElement('span');
  anchors.forEach((anchor) => {
    anchor?.classList.add('js-link-disabled');
    loader?.classList.add('loader', 'loader--xsmall', 'd-block');
    anchor?.appendChild(loader);
  });
}

export function clearLinkDisabledWithSpinner() {
  const anchors = document.querySelectorAll('.js-link-disabled');
  anchors.forEach((anchor) => {
    const loader = anchor?.querySelector('.loader.loader--xsmall.d-block');
    anchor?.classList.remove('js-link-disabled');
    if (loader) {
      anchor?.removeChild(loader);
    }
  });
}
