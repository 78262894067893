/* eslint-disable no-unused-vars */

window.Facets = {
  onBegin: function (data, status, xhr) {
    //    $('#product-list-loader').addClass('product-list-loader--visible');
    //    $('form#update-product-list').trigger('submit');
  },
  onUpdateSuccess: function (data, status, xhr) {
    // console.log('onUpdateSuccess');
    // console.log(xhr);
  },
  onUpdateError: function (xhr, status, error) {
    //console.log(xhr);
    //console.log(status);
    //console.log(error);
    $('#product-list-loader').removeClass('product-list-loader--visible');
  }
};
