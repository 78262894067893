import { showButtonDisabledWithSpinner } from '../helpers/showDisabledWithSpinner';

window.CreditApplication = {
  onBegin: function () {
    showButtonDisabledWithSpinner();
  },
  onSuccess: function (data) {
    if (data.redirectUrl) {
      $('.js-btn-disabled .loader').hide();
      // if the server returned a JSON object containing a redirect url redirect (probably login)
      window.location.href = data.redirectUrl;
    }

    //get the correct form id from the partial html
    var el = $('<div></div>').html(data);
    var form = $('form', el);
    if (form.length === 0) return;
    var formId = form.attr('id');

    //render the captcha if required
    window.reCAPTCHA.render(formId);

    //clear the form values on completion
    if (data.includes('alert-success')) {
      var inputs = $('#' + formId)
        .find(':input')
        .not(':button, :submit, :reset, :hidden, :checkbox, :radio');
      $(inputs).val('');
    }
  },
  onError: function (xhr, status, error) {
    $('.js-btn-disabled .loader').hide();
    console.log('onError');
    console.log(xhr);
    console.log(status);
    console.log(error);
  }
};
