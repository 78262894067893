/* eslint-disable no-unused-vars */

import { debounce } from './shared';

$('.js-autocomplete-input, .site-search__button, .js-rac-item').on(
  'click',
  function () {
    $(this)
      .closest('.js-rac-search-wrapper')
      .find('.js-autocomplete-results')
      .show();
  }
);

// sync both inputs
$('.site-search__input').on('input', function () {
  const val = $(this).val();
  $('.site-search__input')
    .not(this)
    .each(function () {
      $(this).val(val);
    });
});

$('.js-rac-search-wrapper').on('click', 'a.js-rac-item', function (e) {
  var logUrl = $(this).data('click-url');
  if (logUrl != '') {
    $.ajax({
      url: '/umbraco/Surface/SearchSurface/LogRacClickEvent',
      type: 'POST',
      data: JSON.stringify({
        logUrl: logUrl
      }),
      contentType: 'application/json',
      error: function (data) {
        // console.log("Couldn't send click event to record SLI data about autocomplete.")
      }
    });
  }
});

$('.js-related-products, .search-results').on(
  'click',
  '.js-sli-click-trigger a',
  function (e) {
    var logUrl = $(this).closest('.js-sli-click-trigger').data('click-url');
    if (logUrl != '') {
      $.ajax({
        url: '/umbraco/Surface/SearchSurface/LogSliClickEvent',
        type: 'POST',
        data: JSON.stringify({
          logUrl: logUrl
        }),
        contentType: 'application/json',
        error: function (data) {
          // console.log("Couldn't send click event to record SLI data about related products.")
        }
      });
    }
  }
);

$('.js-main-search-autocomplete').on('submit', function (e) {
  var inputField = $(this).find('.js-autocomplete-input');
  if (inputField.val().trim().length == 0) {
    e.preventDefault();
  }
});

$('.js-rac-search-wrapper').on(
  'input',
  '.js-autocomplete-input',
  debounce(function (event) {
    const allResults = $('.js-autocomplete-results');
    const theseResults = $(this).find('.js-autocomplete-results');

    const phrase = $(this).val();
    if (phrase.length >= 2 && phrase.trim() != '') {
      $('.ja-rac-loader').css({ opacity: 1 });
      $.ajax({
        url: '/umbraco/Surface/SearchSurface/GetRichAutocomplete',
        type: 'POST',
        data: JSON.stringify({ phrase: phrase }),
        contentType: 'application/json',
        error: function (data) {
          console.log('There was a problem retrieving autocomplete search:');
          console.log(data);
        },
        success: function (data, textStatus, jqXHR) {
          $('.ja-rac-loader').css({ opacity: 0 });
          allResults.html(data);
          theseResults.show();
        }
      });
    } else {
      $('.js-autocomplete-results').html('');
      return false;
    }
  }, 500)
);

const selectable = 'a.js-rac-item, .js-autocomplete-input';

// disable page scrolling when search box selected
$(window).on('keydown', function (e) {
  // space and arrow keys

  const focusedEl = $(':focus');

  if ($(selectable).is(focusedEl) && [38, 40].indexOf(e.keyCode) > -1) {
    console.log('focused');
    e.preventDefault();
  }
});

// use arrow keys to move between search results
$('.js-rac-search-wrapper').on('keyup', selectable, function (event) {
  const wrapper = $(this).closest('.js-rac-search-wrapper');
  const currentGroup = wrapper.find(selectable);
  const keyCode = event.keyCode;

  const index = currentGroup.index(this);
  const next = currentGroup.eq(index + 1);
  const last = currentGroup.eq(index - 1);

  if (keyCode === 40 || keyCode === 38) {
    event.preventDefault();
    if (keyCode === 40 && index < currentGroup.length) {
      // arrow down
      next.focus();
    }
    if (keyCode === 38 && index > 0) {
      // arrow up
      last.focus();
    }
  }
  if (keyCode == 13) {
    $('.ja-rac-loader').css({ opacity: 0 });
    $('.js-autocomplete-results').html('');
    $('.js-autocomplete-results').hide();
  }
});

// click elsewhere on page to close results
$('body').on('click', function (e) {
  var target = $(e.target);
  if (!target.hasClass('js-autocomplete-input')) {
    $('.js-autocomplete-results').hide();
  }
});
