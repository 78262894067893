/* global process require */
import 'isomorphic-fetch';

/* React */
import(/* webpackPrefetch: false, webpackChunkName: "react" */ 'react')
  .then((module) => {
    const React = module.default;

    if (process.env.NODE_ENV !== 'production') {
      const whyDidYouRender = require('@welldone-software/why-did-you-render');
      whyDidYouRender(React);
    }
  })
  .catch(() => 'An error occurred');

/* ReactDOM */
import(/* webpackPrefetch: false, webpackChunkName: "reactDom" */ 'react-dom')
  .then((module) => {
    const ReactDOM = module.default;

    /* Basket */
    const appBasket = document.getElementById('app-basket');

    if (appBasket) {
      const basketLineCount = parseInt(appBasket.dataset.lineCount, 10);

      import(
        /* webpackPrefetch: false, webpackChunkName: "basket" */ './basket/index'
      )
        .then((module) => {
          const Basket = module.default;

          ReactDOM.render(<Basket lineCount={basketLineCount} />, appBasket);
        })
        .catch(() => 'An error occurred while loading the component');
    }

    /* AddToWishlist */
    const addToWishlistWrapper = document.querySelectorAll(
      '[data-app="add-to-wishlist"]'
    );

    if (addToWishlistWrapper.length > 0) {
      const wrappers = Array.from(addToWishlistWrapper);

      wrappers.forEach((wrapper) => {
        const initialState = JSON.parse(wrapper.dataset.initialState);

        import(
          /* webpackPrefetch: false, webpackChunkName: "addToWishlist" */ './wishlist/AddToWishlist'
        )
          .then((module) => {
            const AddToWishlist = module.default;

            ReactDOM.render(
              <AddToWishlist
                wishlists={initialState.wishlists}
                isLoggedIn={initialState.isLoggedIn}
                catalogueRef={initialState.catalogueRef || ''}
              />,
              wrapper
            );
          })
          .catch(() => 'An error occurred while loading the component');
      });
    }

    /* Wishlist */
    const appWishlist = document.getElementById('app-wishlist');

    if (appWishlist) {
      import(
        /* webpackPrefetch: false, webpackChunkName: "wishlist" */ './wishlist/Wishlist'
      )
        .then((module) => {
          const Wishlist = module.default;

          ReactDOM.render(
            <Wishlist
              initialState={JSON.parse(appWishlist.dataset.initialState)}
            />,
            appWishlist
          );
        })
        .catch(() => 'An error occurred while loading the component');
    }

    /* QuickOrder */
    const appQuickOrder = document.getElementById('app-quick-order');

    if (appQuickOrder) {
      import(
        /* webpackPrefetch: false, webpackChunkName: "quickOrder" */ './quick-order/index'
      )
        .then((module) => {
          const QuickOrder = module.default;

          ReactDOM.render(
            <QuickOrder
              initialState={JSON.parse(appQuickOrder.dataset.initialState)}
            />,
            appQuickOrder
          );
        })
        .catch(() => 'An error occurred while loading the component');
    }

    /* Profile */
    const appProfile = document.getElementById('app-profile');

    if (appProfile) {
      import(
        /* webpackPrefetch: false, webpackChunkName: "profile" */ './profile/Profile'
      )
        .then((module) => {
          const Profile = module.default;

          ReactDOM.render(<Profile />, appProfile);
        })
        .catch(() => 'An error occurred while loading the component');
    }

    /* CostCentres */
    const appCostCentres = document.querySelector(
      '[data-app="app-psf-cost-centres"]'
    );

    if (appCostCentres) {
      const locationCode = appCostCentres.dataset.locationCode;

      import(
        /* webpackPrefetch: false, webpackChunkName: "costCentres" */ './cost-centres/CostCentres'
      )
        .then((module) => {
          const CostCentres = module.default;
          // console.log(locationCode);

          ReactDOM.render(
            <CostCentres locationCode={locationCode || ''} />,
            appCostCentres
          );
        })
        .catch(() => 'An error occurred while loading the component');
    }

    /* Smartflow */
    const appSmartflow = document.getElementById('app-smartflow');

    if (appSmartflow) {
      import(
        /* webpackPrefetch: false, webpackChunkName: "smartflow" */ './smartflow/SmartFlow'
      )
        .then((module) => {
          const SmartFlow = module.default;

          ReactDOM.render(
            <SmartFlow
              initialState={JSON.parse(appSmartflow.dataset.initialState)}
            />,
            appSmartflow
          );
        })
        .catch(() => 'An error occurred while loading the component');
    }

    /* SmartFlowOrdersList */
    const appSmartFlowOrdersList = document.getElementById(
      'app-smartflow-orders-list'
    );

    if (appSmartFlowOrdersList) {
      const initialState = JSON.parse(
        appSmartFlowOrdersList.dataset.initialState
      );
      import(
        /* webpackPrefetch: false, webpackChunkName: "smartflowOrdersList" */ './smartflow/SmartFlowOrdersList'
      )
        .then((module) => {
          const SmartFlowOrdersList = module.default;

          ReactDOM.render(
            <SmartFlowOrdersList
              summary={initialState.summary}
              filters={initialState.filters}
              ordersList={initialState.ordersList}
              buyersList={initialState.buyersList}
              isTransactional={initialState.isTransactional}
              transactionalCurrency={initialState.transactionalCurrency}
              isPendingApprovalPage={initialState.isPendingApprovalPage}
              website={initialState.website}
              enableBackgroundBulkApprovalProcessing={
                initialState.enableBackgroundBulkApprovalProcessing
              }
              defaultOrderHistoryMonths={initialState.defaultOrderHistoryMonths}
            />,
            appSmartFlowOrdersList
          );
        })
        .catch(() => 'An error occurred while loading the component');
    }

    /* ReportsPage */
    const appReports = document.getElementById('app-reports');

    if (appReports) {
      import(
        /* webpackPrefetch: false, webpackChunkName: "reportsPage" */ './reports/ReportsPage'
      )
        .then((module) => {
          const ReportsPage = module.default;

          ReactDOM.render(<ReportsPage />, appReports);
        })
        .catch(() => 'An error occurred while loading the component');
    }

    /* SavedForLater */
    const appSavedForLater = document.getElementById('app-saved-for-later');

    if (appSavedForLater) {
      const initialState = JSON.parse(appSavedForLater.dataset.initialState);
      import(
        /* webpackPrefetch: false, webpackChunkName: "savedForLater" */ './save-for-later/SavedForLater'
      )
        .then((module) => {
          const SavedForLater = module.default;

          ReactDOM.render(
            <SavedForLater
              rows={initialState.rows}
              website={initialState.website}
            />,
            appSavedForLater
          );
        })
        .catch(() => 'An error occurred while loading the component');
    }

    /* ProductList */
    function initProductList() {
      const appProductList = document.getElementById('app-product-list');

      if (appProductList) {
        const initialState = JSON.parse(appProductList.dataset.initialState);

        import(
          /* webpackPrefetch: false, webpackChunkName: "productList" */ '../components/ProductList'
        )
          .then((module) => {
            const ProductList = module.default;

            ReactDOM.render(
              <ProductList
                products={initialState.products}
                transactionalCurrency={initialState.transactionalCurrency}
                showSecondaryPrice={initialState.showSecondaryPrice}
                website={initialState.website}
                isCssFavouritesEnabled={initialState.isCssFavouritesEnabled}
              />,
              appProductList
            );
          })
          .catch(() => 'An error occurred while loading the component');
      }
    }

    initProductList();

    window.initProductList = initProductList;

    /* ProductPage */
    const appProductPage = document.getElementById('app-product');

    if (appProductPage) {
      const initialState = JSON.parse(appProductPage.dataset.initialState);

      import(
        /* webpackPrefetch: false, webpackChunkName: "productPage" */ './product/ProductPage'
      )
        .then((module) => {
          const ProductPage = module.default;

          ReactDOM.render(
            <ProductPage
              isLoggedIn={initialState.isLoggedIn}
              product={initialState.product}
              transactionalCurrency={initialState.transactionalCurrency}
              baseUrl={initialState.baseUrl}
              showMoreOptions={initialState.showMoreOptions}
              showSecondaryPrice={initialState.showSecondaryPrice}
              showProductReviews={initialState.showProductReviews}
              showFeatures={initialState.showFeatures}
              showDocuments={initialState.showDocuments}
              showUsefulLinks={initialState.showUsefulLinks}
              trustPilotBusinessUnitId={initialState.trustPilotBusinessUnitId}
              website={initialState.website}
              isCssFavouritesEnabled={initialState.isCssFavouritesEnabled}
            />,
            appProductPage
          );
        })
        .catch(() => 'An error occurred while loading the component');
    }

    /* RelatedProducts */
    function initRelatedProducts() {
      const appRelatedProducts = document.getElementsByClassName(
        'app-related-products'
      );

      const relatedProducts = Array.from(appRelatedProducts);

      if (relatedProducts.length > 0) {
        import(
          /* webpackPrefetch: false, webpackChunkName: "relatedProducts" */ '../components/RelatedProducts'
        )
          .then((module) => {
            const RelatedProducts = module.default;

            for (var i = 0; i < relatedProducts.length; i++) {
              const thisAppRelatedProducts = relatedProducts[i];
              const initialState = JSON.parse(
                thisAppRelatedProducts.dataset.initialState
              );
              ReactDOM.render(
                <RelatedProducts
                  products={initialState.products}
                  transactionalCurrency={initialState.transactionalCurrency}
                  showSecondaryPrice={initialState.showSecondaryPrice}
                  website={initialState.website}
                />,
                thisAppRelatedProducts
              );
            }
          })
          .catch(() => 'An error occurred while loading the component');
      }
    }

    initRelatedProducts();

    window.initRelatedProducts = initRelatedProducts;

    /* ProductMediaGallery */
    const appProductMediaGallery = document.getElementById(
      'app-product-media-gallery'
    );

    if (appProductMediaGallery) {
      const initialState = JSON.parse(
        appProductMediaGallery.dataset.initialState
      );

      import(
        /* webpackPrefetch: false, webpackChunkName: "productMediaGallery" */ '../components/ProductMediaGallery'
      )
        .then((module) => {
          const ProductMediaGallery = module.default;

          ReactDOM.render(
            <ProductMediaGallery
              images={initialState.images}
              videos={initialState.videos}
              primaryImageUrl={initialState.primaryImageUrl}
            />,
            appProductMediaGallery
          );
        })
        .catch(() => 'An error occurred while loading the component');
    }

    /* IntRegistrationDeliveryAddressDetails */
    const appIntRegistrationDeliveryAddressDetails = document.getElementById(
      'app-int-registration-delivery-address-details'
    );

    if (appIntRegistrationDeliveryAddressDetails) {
      const initialState = JSON.parse(
        appIntRegistrationDeliveryAddressDetails.dataset.initialState
      );

      import(
        /* webpackPrefetch: false, webpackChunkName: "intRegistrationDeliveryAddressDetails" */ './international-registration/DeliveryAddressDetails'
      )
        .then((module) => {
          const DeliveryAddressDetails = module.default;

          ReactDOM.render(
            <DeliveryAddressDetails
              euroCountriesList={initialState.euroCountriesList}
              currencyOptionsList={initialState.currencyOptionsList}
              isoCountryCodes={initialState.isoCountryCodes}
              model={initialState.model}
              website={initialState.website}
            />,
            appIntRegistrationDeliveryAddressDetails
          );
        })
        .catch(() => 'An error occurred while loading the component');
    }

    /* SaveCardDetailsForm */
    function initSaveCardDetails() {
      const appSaveCardDetailsForm = document.getElementById(
        'app-save-card-details'
      );

      if (appSaveCardDetailsForm) {
        import(
          /* webpackPrefetch: false, webpackChunkName: "saveCardDetailsForm" */ './one-click-checkout/SaveCardDetailsForm'
        )
          .then((module) => {
            const SaveCardDetailsForm = module.default;

            ReactDOM.render(
              <SaveCardDetailsForm
                merchantKey={appSaveCardDetailsForm.dataset.merchantKey || ''}
              />,
              appSaveCardDetailsForm
            );
          })
          .catch(() => 'An error occurred while loading the component');
      }
    }

    initSaveCardDetails();

    window.initSaveCardDetails = initSaveCardDetails;

    /* AccountPreviousOrder */
    const appAccountPreviousOrder = document.getElementById(
      'app-acccount-previous-order'
    );

    if (appAccountPreviousOrder) {
      const initialState = JSON.parse(
        appAccountPreviousOrder.dataset.initialState
      );

      import(
        /* webpackPrefetch: false, webpackChunkName: "accountPreviousOrder" */ './account-previous-order/AccountPreviousOrder'
      )
        .then((module) => {
          const AccountPreviousOrder = module.default;

          ReactDOM.render(
            <AccountPreviousOrder
              isError={initialState.isError}
              errors={initialState.errors}
              isTransactional={initialState.isTransactional}
              transactionalCurrency={initialState.transactionalCurrency}
              isInternational={initialState.isInternational}
              isQuote={initialState.isQuote}
              userCountryCode={initialState.userCountryCode}
              orderDetail={initialState.orderDetail}
              emailAddress={initialState.emailAddress}
              website={initialState.website}
              telephoneNumber={initialState.telephoneNumber}
              isReturnsEnabled={initialState.isReturnsEnabled}
            />,
            appAccountPreviousOrder
          );
        })
        .catch(() => 'An error occurred while loading the component');
    }

    /* AccountTabs */
    const appAccountTabs = document.getElementById('app-account-tabs');

    if (appAccountTabs) {
      const initialState = JSON.parse(appAccountTabs.dataset.initialState);

      import(
        /* webpackPrefetch: false, webpackChunkName: "accountPreviousOrder" */ './account-tabs/AccountTabs'
      )
        .then((module) => {
          const AccountTabs = module.default;

          ReactDOM.render(
            <AccountTabs
              approverSummary={initialState.approverSummary}
              buyerSummary={initialState.buyerSummary}
              cssUrl={initialState.cssUrl}
              hasQuotes={initialState.hasQuotes}
              hasOffers={initialState.hasOffers}
              hasReports={initialState.hasReports}
              hasWishlists={initialState.hasWishlists}
              isCssEnabled={initialState.isCssEnabled}
              isTransactional={initialState.isTransactional}
              isWebFlowApprover={initialState.isWebFlowApprover}
              isWebFlowEnabled={initialState.isWebFlowEnabled}
              tabsRedirectUrl={initialState.tabsRedirectUrl}
            />,
            appAccountTabs
          );
        })
        .catch(() => 'An error occurred while loading the component');
    }

    /* Products I Love */
    const appProductsILove = document.getElementById(
      'app-products-i-love-logged-out'
    );

    if (appProductsILove) {
      import(
        /* webpackPrefetch: false, webpackChunkName: "productsILove" */ './products-i-love/ProductsILove'
      )
        .then((module) => {
          const ProductsILove = module.default;

          ReactDOM.render(<ProductsILove />, appProductsILove);
        })
        .catch(() => 'An error occurred while loading the component');
    }
  })
  .catch(() => 'An error occurred');
