import { PageLocation } from '../types/enums';
import {
  GoogleAnalyticsEvent,
  Ecommerce,
  EcommerceListDetails,
  Item,
  ProductViewModel,
  ProductListItemViewModel,
  BasketRowViewModel,
  Ga4CheckoutModel
} from '../types/generated';
import {
  MapProductsToItems,
  MapProductToItem,
  MapBasketRowsToItems,
  MapBasketRowToItem,
  MapCheckoutOrderLinesToItem
} from './ga4Mappers';

/* Add product events  */
export const addGA4ProductListingEvent = (
  products: ProductListItemViewModel[],
  isSearch?: boolean
) => {
  var eventName = 'view_item_list';
  const currentCategory = window.location.pathname?.split('/').slice(-1)[0];
  const listId = isSearch ? 'search' : `plp-${currentCategory}`;
  const listName = isSearch ? 'Search' : `Product List Page ${currentCategory}`;
  const items = MapProductsToItems(products, listId, listName);
  addGA4Event(eventName, items, {
    item_list_id: listId,
    item_list_name: listName
  } as Ecommerce);
};

export const addGA4ProductEvent = (
  eventName: string,
  product: ProductViewModel,
  { currency, value, valueExcVat, item_list_id, item_list_name }: Ecommerce,
  quantity: number = 1
) => {
  const item = MapProductToItem(
    1,
    product,
    quantity,
    item_list_id,
    item_list_name
  );
  addGA4Event(eventName, [item], {
    currency: currency ?? 'GBP',
    value: value ?? item.price,
    valueExcVat: valueExcVat ?? item.priceExcVat,
    item_list_id: item_list_id ?? '',
    item_list_name: item_list_name ?? ''
  } as Ecommerce);
};

/* Add basket events  */
export const addGA4BasketRowsEvent = (
  eventName: string,
  basketRows: BasketRowViewModel[],
  {
    currency = 'GBP',
    value,
    valueExcVat,
    discount,
    shipping,
    item_list_id,
    item_list_name,
    coupon
  }: Ecommerce
) => {
  const items = MapBasketRowsToItems(basketRows, item_list_id, item_list_name);
  addGA4Event(eventName, items, {
    currency: currency,
    value: value,
    valueExcVat: valueExcVat,
    discount: discount,
    shipping: shipping,
    item_list_id: item_list_id ?? '',
    item_list_name: item_list_name ?? '',
    coupon: coupon
  } as Ecommerce);
};

export const addGA4BasketRowEvent = (
  eventName: string,
  basketRow: BasketRowViewModel,
  {
    currency = 'GBP',
    value,
    valueExcVat,
    discount,
    shipping,
    item_list_id,
    item_list_name,
    coupon
  }: Ecommerce
) => {
  const item = MapBasketRowToItem(0, basketRow, item_list_id, item_list_name);
  addGA4Event(eventName, [item], {
    currency: currency,
    value: value,
    valueExcVat: valueExcVat,
    discount: discount,
    shipping: shipping,
    item_list_id: item_list_id ?? '',
    item_list_name: item_list_name ?? '',
    coupon: coupon
  } as Ecommerce);
};

/* Add Checkout Events */ [];
export const addGA4CheckoutEvent = (
  eventName: string,
  shippingTier: string = '',
  paymentType: string = ''
) => {
  var model = (window as any).ga4CheckoutModel;
  const items = MapCheckoutOrderLinesToItem(model);
  addGA4Event(eventName, items, {
    currency: model?.currency,
    value: model?.totalIncVat,
    valueExcVat: model?.totalValue,
    tax: model?.tax,
    shipping: model?.shipping,
    discount: model?.discount,
    shipping_tier: shippingTier != '' ? shippingTier : model?.shippingTier,
    payment_type: paymentType != '' ? paymentType : model?.paymentMethod,
    coupon: model.promoCode
  } as Ecommerce);
};

export const addGA4CheckoutCompleteEvent = (model: Ga4CheckoutModel) => {
  var eventName = 'purchase';
  const items = MapCheckoutOrderLinesToItem(model);
  addGA4Event(eventName, items, {
    currency: model?.currency,
    value: model?.totalIncVat,
    valueExcVat: model?.totalValue,
    transaction_id: model?.transactionId,
    tax: model?.tax,
    shipping: model?.shipping,
    discount: model?.discount,
    shipping_tier: model?.shippingTier,
    payment_type: model?.paymentMethod,
    coupon: model?.promoCode
  } as Ecommerce);
};

/* Add the event to the data layer */
export const pushGA4Event = (event: GoogleAnalyticsEvent) => {
  // clear the previous ecommerce object.
  (window as any).dataLayer.push({ ecommerce: null });
  //add the new event
  (window as any).dataLayer.push(event);
};

export const getGA4ListId = (location: PageLocation): string => {
  const listId = getGA4ListDetails(location).item_list_id ?? '';
  return listId;
};

export const getGA4ListName = (location: PageLocation): string => {
  const listName = getGA4ListDetails(location).item_list_name ?? '';
  return listName;
};

export const toListId = (input: string): string => {
  const listId = input
    .trim()
    .toLowerCase()
    .replace(/[^a-z0-9]+/g, '-')
    .replace(/^-+/, '');
  return listId;
};

export const calculateLinePrice = (
  unitPrice: number,
  quantity: number
): number => {
  return Math.round(unitPrice * quantity * 100) / 100;
};

export const calculateBasketTotal = (
  subTotal: number,
  shippingTotal: number,
  discountTotal: number,
  taxTotal: number = 0
): number => {
  return (
    Math.round((subTotal + shippingTotal + discountTotal + taxTotal) * 100) /
    100
  );
};

const getGA4ListDetails = (location: PageLocation): Ecommerce => {
  let listDetails = {
    item_list_id: '',
    item_list_name: ''
  } as EcommerceListDetails;
  switch (location) {
    case PageLocation.PLP:
      listDetails.item_list_id = 'plp';
      listDetails.item_list_name = 'PLP';
      break;
    case PageLocation.YMAL:
      listDetails.item_list_id = 'pdp-ymal';
      listDetails.item_list_name = 'PDP You May Also Like';
      break;
    default:
      listDetails.item_list_id = '';
      listDetails.item_list_name = '';
  }
  return listDetails;
};

const addGA4Event = (
  eventName: string,
  items: Item[],
  {
    item_list_id,
    item_list_name,
    currency,
    value,
    valueExcVat,
    discount,
    shipping_tier,
    payment_type,
    coupon,
    transaction_id,
    tax,
    shipping
  }: Ecommerce
) => {
  let ecommerce = {
    item_list_id: item_list_id,
    item_list_name: item_list_name,
    currency: currency,
    value: value,
    valueExcVat: valueExcVat,
    discount: discount,
    items: items,
    shipping_tier: shipping_tier,
    payment_type: payment_type,
    coupon: coupon,
    transaction_id: transaction_id,
    tax: tax,
    shipping: shipping
  } as Ecommerce;

  //remove empty values
  Object.keys(ecommerce).forEach(
    (k) =>
      ecommerce[k as keyof Ecommerce] == null &&
      delete ecommerce[k as keyof Ecommerce]
  );

  pushGA4Event({
    event: eventName,
    ecommerce: ecommerce
  } as GoogleAnalyticsEvent);
};
