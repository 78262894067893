import { BasketAddedRow } from './components/BasketProductList/BasketRowModel';
import {
  BasketRowViewModel,
  AlternativeProductType
} from '../../types/generated';

export const apiBasePath = '/umbraco/surface/BasketSurface/';
export const checkoutApiBasePath = '/umbraco/surface/CheckoutSurface/';

const fetchBaseConfig: RequestInit = {
  credentials: 'same-origin',
  headers: {
    'content-type': 'application/json',
    accept: 'application/json'
  }
};

export const getBasket = () => {
  const path = `${apiBasePath}BasketPageInner`;

  return fetch(path, fetchBaseConfig).then((response) => response.json());
};

export const clearBasket = () => {
  const path = `${apiBasePath}ClearBasket`;
  return fetch(path, {
    ...fetchBaseConfig,
    method: 'POST'
  }).then((response) => response.json());
};

export const addToBasket = (
  productId: number,
  quantity: number,
  catalogueRef: string | null,
  showMinQtyMessage?: boolean
) => {
  const path = `${apiBasePath}AddToBasket`;
  return fetch(path, {
    ...fetchBaseConfig,
    method: 'POST',
    body: JSON.stringify({
      productId,
      quantity,
      catalogueReference: catalogueRef,
      showMinQtyMessage
    })
  }).then((response) => response.json());
};

export interface SwapItem<T> {
  (
    currentOrderLineId: number,
    catalogueRef: string | null,
    alternativeProductType: AlternativeProductType,
    modifier: string | null,
    price: number
  ): T;
}

export const switchItem: SwapItem<Promise<any>> = (
  currentOrderLineId,
  catalogueRef,
  alternativeProductType,
  modifier,
  price
) => {
  const path = `${apiBasePath}ApplyAlternativeProduct`;
  return fetch(path, {
    ...fetchBaseConfig,
    method: 'POST',
    body: JSON.stringify({
      currentOrderLineId,
      targetCatalogueReference: catalogueRef,
      alternativeProductType,
      modifier,
      price
    })
  }).then((response) => response.json());
};

export const switchAll = () => {
  const path = `${apiBasePath}SwapToAlternativeProducts`;
  return fetch(path, {
    ...fetchBaseConfig,
    method: 'POST'
  }).then((response) => response.json());
};

export const getProductInfo = (catalogueRef: string) => {
  const path = `${apiBasePath}GetProductInfo?catalogueRef=${catalogueRef}`;
  return fetch(path, {
    ...fetchBaseConfig
  }).then((response) => response.json());
};

export const applyChanges = (
  updatedItems: BasketRowViewModel[],
  addedItems: BasketAddedRow[]
) => {
  const path = `${apiBasePath}ApplyChanges`;
  return fetch(path, {
    ...fetchBaseConfig,
    method: 'POST',
    body: JSON.stringify({
      updatedItems,
      addedItems
    })
  }).then((response) => response.json());
};

export const clearPromo = () => {
  const path = `${apiBasePath}ClearBasketPromoCode`;
  return fetch(path, {
    ...fetchBaseConfig,
    method: 'POST'
  }).then((response) => response.json());
};

export const applyPromo = (promotionCode: string) => {
  const path = `${apiBasePath}UpdateBasketPromotionCode`;
  return fetch(path, {
    ...fetchBaseConfig,
    method: 'POST',
    body: JSON.stringify({
      promotionCode
    })
  }).then((response) => response.json());
};

export const clearPo = () => {
  const path = `${apiBasePath}ClearBasketPO`;
  return fetch(path, {
    ...fetchBaseConfig,
    method: 'POST'
  }).then((response) => response.json());
};

export const applyPo = (poNumber: string) => {
  const path = `${apiBasePath}UpdateBasketPO`;
  return fetch(path, {
    ...fetchBaseConfig,
    method: 'POST',
    body: JSON.stringify({
      poNumber
    })
  }).then((response) => response.json());
};

export const addQuickItem = (catalogueReference: string, quantity: number) => {
  const path = `${apiBasePath}QuickAddToBasket`;
  return fetch(path, {
    ...fetchBaseConfig,
    method: 'POST',
    body: JSON.stringify({
      catalogueReference,
      quantity
    })
  }).then((response) => response.json());
};

export const getBasketCatalogueRefs = () => {
  const path = `${apiBasePath}BasketCatalogueRefs`;
  return fetch(path, fetchBaseConfig).then(
    (response) => response.json() as unknown as string[]
  );
};
