/* eslint-disable no-unused-vars */

import popup from '../utilities/popup-alert';

window.WebFlow = {
  onAmendBegin: function (data, status, xhr) {
    $('.js-webflow-ponumber-loader').css('opacity', 1);
    $('.js-webflow-ponumber-button').attr('disabled', '');
  },
  onAmendSuccess: function (data, status, xhr) {
    popup.show(
      'You have successfully updated purchase order number.',
      popup.types.positive,
      popup.icons.info
    );
    $('.js-webflow-ponumber-loader').css('opacity', 0);
    $('.js-webflow-ponumber-button').removeAttr('disabled');
  },
  onAmendFailure: function (xhr, status, error) {
    popup.show(
      'There was a problem amending purchase order number.',
      popup.types.negative,
      popup.icons.negative
    );
  }
};

window.WebFlowRecreateBasket = {
  onBegin: function (data, status, xhr) {
    $('.js-readd-basket .loader').show();
  },
  onUpdateSuccess: function (data, status, xhr) {
    $('.header-icon__badge').load(
      '/umbraco/surface/basketsurface/getbasketitemcount'
    );
    $('.js-readd-basket .loader').hide();
    $('html, body').animate(
      {
        scrollTop: 0
      },
      200
    );
    popup.show(
      'Available items added to your basket',
      popup.types.positive,
      popup.icons.positive
    );
  },
  onUpdateError: function (xhr, status, error) {
    popup.show(
      'Unable to add the  items to your basket',
      popup.types.negative,
      popup.icons.negative
    );
  }
};
