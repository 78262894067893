import { Ecommerce, ProductViewModel } from '../types/generated';
import { addGA4ProductEvent } from './ga4';
import * as api from '../apps/basket/basketApi';

export const addPreviousOrderProductToCartEvent = (catalogueRef: string) => {
  var eventName = 'add_to_cart';
  api.getProductInfo(catalogueRef).then((data: ProductViewModel) => {
    addGA4ProductEvent(
      eventName,
      data as ProductViewModel,
      {
        currency: 'GBP',
        value: data?.priceIncVat,
        valueExcVat: data?.priceExcVat
      } as Ecommerce
    );
  });
};
