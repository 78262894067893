import popupAlert from '../utilities/popup-alert';
import { updateDatePicker } from '../domtools/delivery.js';

window.AccountDetails = {
  onBegin: function () {},
  onUpdateSuccess: function () {
    $.validator.unobtrusive.parse('#profileDeliveryAddressForm');
  },
  onUpdateError: function () {}
};

window.AccountDefaultDetails = {
  onBegin: function () {
    $.validator.unobtrusive.parse('#profileDeliveryAddressForm');
  },
  onUpdateSuccess: function () {},
  onUpdateError: function () {}
};

window.AccountBTSCalendar = {
  onBegin: function () {
    $.validator.unobtrusive.parse('#profileBTSCalendarForm');
    var elementLoader = $(this).find('.js-accd-loader .loader');
    $(elementLoader).removeClass('loader--hidden');
    $(this).find('#btn_submit').attr('disabled', true);
  },
  onUpdateSuccess: function () {
    if (
      $('#checkout').length > 0 &&
      $('#confirmCalendarDelayDelivery').length > 0
    ) {
      $('#isCalendarConfirmed').val(true);
      $('#btn-sbmt-delivery-optn').attr('disabled', 'disabled');
      var datepicker = $('#delayed-delivery-date')
        .datepicker()
        .data('datepicker');
      datepicker.clear();
      updateDatePicker();
    }
  },
  onUpdateError: function () {
    var elementLoader = $(this).find('.js-accd-loader .loader');
    $(elementLoader).addClass('loader--hidden');
    $(this).find('#btn_submit').attr('disabled', false);
  }
};

window.AccountBTSCalendarConfirmOpen = {
  onBegin: function (data, status, xhr) {
    window.AccountBTSCalendar.onBegin(data, status, xhr);
  },
  onUpdateSuccess: function (data) {
    var addressId = $('input[name=AddressId]', $(data)).val();
    $('#' + addressId).attr('checked', false);
    popupAlert.showPositive("We've successfully saved your calendar.");
  },
  onUpdateError: function (xhr, status, error) {
    window.AccountBTSCalendar.onUpdateError(error, status, xhr);
  },
  submitForm: function (form) {
    //the ajax form will not post back in an ajax fashion by calling form submit from checkbox change
    //so we need to create a hidden button and click it
    var button = form.ownerDocument.createElement('input');
    button.style.display = 'none';
    button.type = 'submit';
    form.appendChild(button).click();
    form.removeChild(button);
  }
};

window.AccountDelivery = {
  onBegin: function () {
    $('.js-accp-loader .loader').removeClass('loader--hidden');
  }
};

window.OneClickPaymentMethod = {
  onBegin: function () {
    $('#profilePaymentMethodForm .js-accd-loader .loader').removeClass(
      'loader--hidden'
    );
  },
  onUpdateSuccess: function (data) {
    // SagePay configuration
    var loader = $('.js-form-loader');
    var hideForm = loader.data('hide-choice-form');

    if (hideForm) {
      loader.remove();
      $('.js-cdf').removeClass('hidden');
      window.initSaveCardDetails();
    }

    // PayPal
    var form = $(data);
    var showPopup = form
      .find('.js-acount-default-payment-form')
      .data('trigger-popup');
    if (showPopup === 'True') {
      popupAlert.showPositive('Redirecting to PayPal ...');
    }
  }
};
