$(document).ready(function () {
  $(window).scroll(function () {
    const siteHeader = $('.site-header');
    const bottomOfHeaderPosition =
      siteHeader.scrollTop() + siteHeader.offset().top + siteHeader.height();
    const focused = $('a.js-rac-item:focus, .js-autocomplete-input:focus');

    if ($(this).scrollTop() > bottomOfHeaderPosition) {
      $('.js-sticky').addClass('is-sticky');
      $('.js-autocomplete-results:eq(0)').fadeOut(300);

      if (focused.length > 0) {
        $('.js-autocomplete-results:eq(1)').fadeIn(300);
      }
    } else {
      $('.js-sticky').removeClass('is-sticky');
      $('.js-autocomplete-results:eq(1)').fadeOut(300);

      if (focused.length > 0) {
        $('.js-autocomplete-results:eq(0)').fadeIn(300);
      }
    }
  });

  $('.js-header-icon-link').on('click', () => {
    if ($('.js-header-icon').hasClass('hidden')) {
      return false;
    }

    $('.js-header-icon').addClass('hidden');
    $('.js-spinner-icon').removeClass('hidden');
  });
});
