$(document).ready(() => {
    const cookiebar = $('#cookie-alert');
    // cookie warning
    if (navigator.cookieEnabled) {
        if (/(^|;)\s*cookieaccepted=true/.test(document.cookie)) {
            // alert('Hello again - no cookie warning for you!');
        } else {
            cookiebar.fadeIn(500);
        }
    }

    // for testing the cookie warning.
    if (location.href.match(/(\?|&)showcookiewarning($|&|=)/)) {
        document.cookie = `cookieaccepted=false; max-age=${  2592000000  }; path=/`; // 30 days
        cookiebar.slideDown(500);
    }

    $('#cookie-alert-buttons').click(() => {
        document.cookie = `cookieaccepted=true; max-age=${  2592000000  }; path=/`; // 30 days
        cookiebar.slideUp(500);
        return false;
    });

    // should hide cookie warning after first time it's been viewed
    document.cookie = `cookieaccepted=true; max-age=${  2592000000  }; path=/`; // 30 days
});
