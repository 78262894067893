/* eslint-disable no-redeclare */
/*globals $ */

window.Products = {
  onUpdateSuccess(/*data, status, xhr*/) {
    $('#product-list-loader').removeClass('product-list-loader--visible');
    // rerun react fron end
    window.initProductList();
  },
  onUpdateError(/*xhr, status, error*/) {
    $('#product-list-loader').removeClass('product-list-loader--visible');
  }
};
