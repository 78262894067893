/* eslint-disable no-unused-vars */

window.pure360Newsletter = {
  onBegin: function (data, status, xhr) {},
  onSuccess: function (data, status, xhr) {},
  onError: function (xhr, status, error) {}
};

window.pure360NewsletterButton = {
  onBegin: function (data, status, xhr) {},
  onSuccess: function (data, status, xhr) {},
  onError: function (xhr, status, error) {}
};
