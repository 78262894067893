import 'air-datepicker';
import 'air-datepicker/dist/js/i18n/datepicker.en';

var dateRange = [];

$(document).ready(function () {
  setupDatePicker();

  var isConfirmed = $('#isCalendarConfirmed').val();
  if (isConfirmed != null && isConfirmed.toLowerCase() == 'true') {
    updateDatePicker();
  }
});

export function updateDatePicker() {
  var elementLoader = '#radio-loader';
  var elementRadioList = '.radio-list';
  $(elementLoader).html(`
        <div class="mt-3 mb-5">
            <div class="loader-mask">
                <div class="loader-mask__title">
                    Updating your calendar
                </div>
                <div class="loader loader--small"></div>
            </div>
        </div>`);
  $(elementRadioList).css('display', 'none');
  $.ajax({
    url: '/umbraco/Surface/CheckoutSurface/GetCalendarHolidays',
    type: 'GET',
    contentType: 'application/json',
    error: function (data) {
      console.log(data);
      $(elementLoader).html('');
      $(elementRadioList).css('display', '');
    },
    success: function (holidayRange) {
      dateRange = holidayRange;
      $('#delayed-delivery-date').datepicker('destroy');
      setupDatePicker();
      $(elementLoader).html('');
      $(elementRadioList).css('display', '');
    }
  });
}

var setupDatePicker = function () {
  let currentDate = new Date();

  if ($('#delayDeliveryStartDate').val() != null) {
    var dateValues = $('#delayDeliveryStartDate').val().split('/');
    currentDate = new Date(
      new Date(dateValues[1] + '/' + dateValues[0] + '/' + dateValues[2])
    );
  }

  let prevDate;
  const disabledDays = [0, 6]; // sunday/saturday

  const datePicker = $('#delayed-delivery-date');

  datePicker.datepicker({
    firstDay: 1,
    inline: true,
    language: 'en',
    dateFormat: 'dd/mm/yyyy',
    showOtherMonths: false,
    showOtherYears: false,
    selectOtherYears: false,
    minDate: new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate()
    ),
    maxDate: new Date(
      currentDate.getFullYear() + 1,
      currentDate.getMonth(),
      currentDate.getDate()
    ),
    onRenderCell: function (date, cellType) {
      const dayOfMonth = date.getDate();
      const dayOfWeek = date.getDay();
      const month = date.getMonth();
      let isDisabled = false;

      // TODO: uncomment this once we revert the xmas holidays change
      //if (cellType === 'day') {
      //    isDisabled = disabledDays.indexOf(dayOfWeek) !== -1;
      //    if (month === 11) {
      //        if (dayOfMonth === 25 || dayOfMonth === 26) {
      //            isDisabled = true;
      //        }
      //    } else if (month === 0 && dayOfMonth === 1) {
      //        isDisabled = true;
      //    }
      //}

      // adding xmas holidays change for now
      var newYearHolidays = [1, 2, 3, 4, 5, 6];
      var xmasHolidays = [24, 25, 26, 27, 28, 29, 30, 31];

      if (cellType === 'day') {
        isDisabled = disabledDays.indexOf(dayOfWeek) !== -1;
        if (month === 11) {
          if (xmasHolidays.indexOf(dayOfMonth) !== -1) {
            isDisabled = true;
          }
        } else if (month === 0 && newYearHolidays.indexOf(dayOfMonth) !== -1) {
          isDisabled = true;
        }
      }

      var cssClass = '';

      if (dateRange && dateRange.length > 0 && !isDisabled) {
        var stringDate = `${('0' + date.getDate()).slice(-2)}/${(
          '0' +
          (date.getMonth() + 1)
        ).slice(-2)}/${date.getFullYear()}`;
        isDisabled = dateRange.indexOf(stringDate) != -1;
        if (isDisabled) {
          cssClass = 'datepickers-holidays';
        }
      }

      return {
        disabled: isDisabled,
        classes: cssClass
      };
    },
    onSelect: function (formattedDate) {
      var hasCalendar = $('#hasCalendars').val();

      if (!hasCalendar) return;

      if (!formattedDate) return;

      if (prevDate != undefined && prevDate == formattedDate) return;
      prevDate = formattedDate;

      $('#btn-sbmt-delivery-optn').removeAttr('disabled');
    }
  });

  const chosenDate = datePicker.val();

  if (chosenDate) {
    const [day, month, year] = chosenDate.split('/');

    datePicker.data('datepicker').selectDate(new Date(year, month - 1, day));
  }
};
