/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */

/**
 * Lazyloading images
 */

import 'lazysizes';

//Common across all pages

import 'jquery';
import 'jquery-validation';
import 'jquery-validation-unobtrusive';
import 'jquery-ajax-unobtrusive';

import 'script-loader!uglify-loader!../../../node_modules/bootstrap/js/dist/util.js';
import 'script-loader!uglify-loader!../../../node_modules/bootstrap/js/dist/carousel.js';
import 'script-loader!uglify-loader!../../../node_modules/bootstrap/js/dist/modal.js';

/**
 * Helpers
 */
import { v4 as uuidv4 } from 'uuid';
import { removeDuplicateObjValuesFromArray } from './helpers/removeDuplicateObjValuesFromArray';

window.Helpers = {
  uuidv4,
  removeDuplicateObjValuesFromArray
};
