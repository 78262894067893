// standard icon options
const icons = {
  warning: 'exclamation',
  negative: 'exclamation',
  positive: 'check',
  info: 'info'
};

// standard type options
const types = {
  negative: 'danger',
  positive: 'success',
  info: 'info',
  warning: 'warning',
  softNegative: 'softNegative'
};

/**
 * generate - returns a jquery alert object
 *
 * @param {string} alertText
 * @param {string} alertType
 * @param {string} iconName
 * @param {number} timeToDisplay
 * @param {string} linkText Optional link text
 * @param {string} href Optional link href
 * @returns {jQuery}
 */

function generate(
  alertText: string,
  alertType: string,
  iconName: string,
  timeToDisplay: number,
  linkText?: string,
  href?: string
) {
  const icon = `<svg class="icon"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="/bundles/svg/sprite.svg#${iconName}"></use></svg>`;

  const notification = $(
    `<div class="alert alert-${alertType}" style="display: none">
      ${iconName ? icon : ''}${alertText} ${
      !!href && !!linkText ? `<a href=${href}>${linkText}</a>` : ''
    }
    </div>`
  );

  return notification.fadeIn(300).delay(timeToDisplay).fadeOut(300);
}

/**
 * show - add a popup to the current page
 *
 * @param {string} alertText
 * @param {string} alertType
 * @param {string} iconName
 * @param {number} timeToDisplay Optional display time
 * @param {string} linkText Optional link text
 * @param {string} href Optional link href
 */

function show(
  alertText: string,
  alertType: string,
  iconName: string,
  timeToDisplay?: number,
  linkText?: string,
  href?: string
) {
  if (timeToDisplay === null || timeToDisplay === undefined)
    timeToDisplay =
      alertType === types.negative || alertType === types.warning
        ? 10000
        : 5000;
  $('.popup-notifications').prepend(
    generate(alertText, alertType, iconName, timeToDisplay, linkText, href)
  );
}

function showPositive(alertText: string) {
  show(alertText, types.positive, icons.positive);
}
function showNegative(alertText: string) {
  show(alertText, types.negative, icons.negative);
}
function showSoftNegative(alertText: string) {
  show(alertText, types.softNegative, '');
}
function showWarning(alertText: string) {
  show(alertText, types.warning, icons.warning);
}
function showInfo(alertText: string) {
  show(alertText, types.info, icons.info);
}

export default {
  generate,
  show,
  showPositive,
  showNegative,
  showWarning,
  showSoftNegative,
  showInfo,
  icons,
  types
};
