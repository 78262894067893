import {
  BasketRowViewModel,
  Item,
  ProductListItemViewModel,
  Ga4CheckoutModel,
  Ga4CheckoutOrderLine
} from '../types/generated';

export const MapProductsToItems = (
  products: ProductListItemViewModel[],
  listId?: string,
  listName?: string
) => {
  let items = [] as Item[];
  for (let i = 0; i < products.length; i++) {
    const product = products[i];
    const item = MapProductToItem(i + 1, product, undefined, listId, listName);
    items.push(item);
  }
  return items;
};

export const MapProductToItem = (
  index: number,
  product: ProductListItemViewModel,
  quantity: number = 1,
  listId?: string,
  listName?: string
) => {
  const item = {
    item_id: product.prodigiItemCode,
    item_ref: product.catalogueRef,
    item_sku: product.sku,
    item_name: product.name,
    index: index,
    //"item_brand": "",
    //"item_variant": "",
    price: product.priceIncVat,
    priceExcVat: product.priceExcVat,
    quantity: quantity,
    //promotion_id: ,
    //promotionName: ,
    //coupon: ,
    //discount?: ,
    item_list_id: listId,
    item_list_name: listName
  } as Item;
  ensureCategoryValues(item, product);
  return item;
};

export const MapBasketRowsToItems = (
  basketRows: BasketRowViewModel[],
  listId?: string,
  listName?: string
) => {
  let items = [] as Item[];
  for (let i = 0; i < basketRows.length; i++) {
    const basketRow = basketRows[i];
    const item = MapBasketRowToItem(i + 1, basketRow, listId, listName);
    items.push(item);
  }
  return items;
};

export const MapBasketRowToItem = (
  index: number,
  basketRow: BasketRowViewModel,
  listId?: string,
  listName?: string
) => {
  const item = {
    item_id: basketRow.ga4AdditionalDetails?.prodigiItemCode,
    item_ref: basketRow.catalogueRef,
    item_sku: basketRow.sku,
    item_name: basketRow.productName,
    index: index,
    item_category: basketRow.ga4AdditionalDetails?.category1,
    item_category2: basketRow.ga4AdditionalDetails?.category2,
    item_category3: basketRow.ga4AdditionalDetails?.category3,
    //"item_brand": "",
    //"item_variant": "",
    price: basketRow.priceIncVat,
    priceExcVat: basketRow.priceExcVat,
    quantity: basketRow.quantity,
    //promotion_id: ,
    //promotionName: ,
    //coupon: ,
    //discount?: ,
    item_list_id: listId,
    item_list_name: listName
  } as Item;
  return item;
};

export const MapCheckoutOrderLinesToItem = (
  checkoutModel: Ga4CheckoutModel,
  listId?: string,
  listName?: string
) => {
  let items = [] as Item[];
  if (checkoutModel.orderLines) {
    for (let i = 0; i < checkoutModel.orderLines.length; i++) {
      const delOrderLine = checkoutModel.orderLines[i];
      const item = MapCheckoutOrderLineToItem(
        i + 1,
        delOrderLine,
        listId,
        listName
      );
      items.push(item);
    }
  }
  return items;
};

export const MapCheckoutOrderLineToItem = (
  index: number,
  orderLine: Ga4CheckoutOrderLine,
  listId?: string,
  listName?: string
) => {
  const item = {
    item_id: orderLine.additionalDetails?.prodigiItemCode,
    item_ref: orderLine.catalogueRef,
    item_sku: orderLine.sku,
    item_name: orderLine.productName,
    index: index,
    item_category: orderLine.additionalDetails?.category1,
    item_category2: orderLine.additionalDetails?.category2,
    item_category3: orderLine.additionalDetails?.category3,
    //"item_brand": "",
    //"item_variant": "",
    price: orderLine.priceIncVat,
    priceExcVat: orderLine.price,
    quantity: orderLine.quantity,
    //promotion_id: ,
    //promotionName: ,
    //coupon: orderLine.promoCode,
    //discount?: ,
    item_list_id: listId,
    item_list_name: listName
  } as Item;
  return item;
};

/* helper functions */
const ensureCategoryValues = (
  item: Item,
  product: ProductListItemViewModel
) => {
  if (product?.category1 && product.category2 && product.category3) {
    item.item_category = product.category1;
    item.item_category2 = product.category2;
    item.item_category3 = product.category3;
    return;
  }

  const urlTail = product.urlPath;
  let parts = urlTail?.split('/');
  if (parts && parts.length >= 5) {
    item.item_category = parts[2] || '';
    item.item_category2 = parts[3] || '';
    item.item_category3 = parts[4] || '';
  }
};
