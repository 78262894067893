import { showButtonDisabledWithSpinner } from '../helpers/showDisabledWithSpinner';

window.NpsFeedback = {
  onBegin: function () {
    showButtonDisabledWithSpinner();
    $('#form-nps-feedback-submit').hide();
    $(this)
      .closest('.form-nps-feedback__body')
      .append('<div class="content-loader"></div>');
  },
  onSuccess: function (data) {
    if (data.redirectUrl) {
      // if the server returned a JSON object containing a redirect url redirect (probably login)
      window.location.href = data.redirectUrl;
      return;
    }

    var el = $('<div></div>').html(data);
    var form = $('form', el);
    if (form.length === 0) return;
    var formId = form.attr('id');

    //render the captcha if required
    window.reCAPTCHA.render(formId);

    //clear the form values on completion
    if (data.includes('alert-success')) {
      var inputs = $('#' + formId)
        .find(':input')
        .not(':button, :submit, :reset, :hidden, :checkbox, :radio');
      $(inputs).val('');
    }
  },
  onError: function (xhr, status, error) {
    console.log(xhr);
    console.log(status);
    console.log(error);
  }
};
