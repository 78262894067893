/*------------------------------------*|
    #RESPONSIVE-NAV-TOGGLE
|*------------------------------------*/

$('body').on('click', '.js-site-nav-toggle', function () {
    var target = $(this).data('target');
    $(target).toggleClass('is-open');
    $('body').toggleClass('nav-open');
    return false;
});

$('.slide-area').on('click', function (e) {
    if (e.target !== this)
        return;
        $(this).removeClass('is-open');
        $('body').toggleClass('nav-open');        
});

/*------------------------------------*|
    #SUBMENU-EXPANSION
|*------------------------------------*/
$('.js-submenu-expand').on('click', function () {
    var linkWrapper = $(this).closest('.site-menu__link-wrapper'),
        linkSelectedClass = 'site-menu__link-wrapper--open',
        menuOpenClass = 'site-menu--open',
        menuItem = linkWrapper.closest('.site-menu__item'),
        subMenu = menuItem.find('.site-menu').first();


    if (linkWrapper.hasClass(linkSelectedClass)) {
        menuItem.find('.site-menu__link-wrapper').removeClass(linkSelectedClass);
        menuItem.find('.site-menu').removeClass(menuOpenClass);
        menuItem.removeClass('site-menu__item--selected').find('.site-menu__item').removeClass('site-menu__item--selected');
    } else {
        linkWrapper.addClass(linkSelectedClass);
        subMenu.addClass(menuOpenClass);
        menuItem.addClass('site-menu__item--selected');
    }

    return false;
});