/* eslint-disable no-unused-vars */

/**
 * Dropdown Menus
 */

let openClass = 'is-open';

$(document).on('click', function (event) {
  $('.dropdown').removeClass(openClass);
});

$('.dropdown-toggle').on('click', function (event) {
  event.stopPropagation();
  let dropdown = $(this).closest('.dropdown');
  if (dropdown.hasClass(openClass)) {
    dropdown.removeClass(openClass);
  } else {
    $('.dropdown').not(dropdown).removeClass(openClass);
    dropdown.addClass(openClass);
  }
  return false;
});

$('.dropdown').on('click', function (event) {
  event.stopPropagation();
});
