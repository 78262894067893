import { showButtonDisabledWithSpinner } from '../helpers/showDisabledWithSpinner';

window.LeadGeneration = {
  onBegin: function () {
    showButtonDisabledWithSpinner();
  },
  onSuccess: function (data) {
    //get the correct form id from the partial html
    var el = $('<div></div>').html(data);
    var form = $('form', el);
    if (form.length === 0) return;
    var formId = form.attr('id');

    //render the captcha if required
    window.reCAPTCHA.render(formId);

    //clear the form values on completion
    if (data.includes('alert-success')) {
      var inputs = $('#' + formId)
        .find(':input')
        .not(':button, :submit, :reset, :hidden, :checkbox, :radio');
      $(inputs).val('');
    }

    //show/hide the other webfms system field
    var choice = $('.js-webfms-system-choice').val();
    if (choice == 'other') {
      $('.js-webfms-other-systems').show();
    } else {
      $('.js-webfms-other-systems').hide();
    }
  },
  onError: function (error) {
    console.log(error);
  }
};
