/* eslint-disable no-unused-vars */

import popup from '../utilities/popup-alert';

window.SetFavourites = {
  onBegin: function (data, status, xht) {
    $('.js-set-favourite-btn').attr('disabled', 'disabled');
    $('.js-set-favourite-btn .loader').show();
  },
  onUpdateError: function (xhr, status, error) {
    console.log(xhr);
    console.log(status);
    console.log(error);
  }
};

window.RemoveFromFavourites = {
  onUpdateSuccess: function (data, status, xhr) {
    $('.js-set-favourites-form').html(data);
    popup.show(
      'Order has been successfully removed from favourites',
      popup.types.positive,
      popup.icons.positive
    );
    $('.js-set-favourite-btn').removeAttr('disabled');
    $('.js-set-favourite-btn .loader').hide();
  }
};

window.AddToFavourites = {
  onUpdateSuccess: function (data, status, xhr) {
    $('.js-set-favourites-form').html(data);
    popup.show(
      'Order has been successfully added to favourites',
      popup.types.positive,
      popup.icons.positive
    );
    $('.js-set-favourite-btn').removeAttr('disabled');
    $('.js-set-favourite-btn .loader').hide();
  }
};

window.ReOrder = {
  onBegin: function (data, status, xhr) {
    $('.js-readd-basket .loader').show();
    $('#reorder-form-top > button').attr('disabled', 'disabled');
    $('#reorder-form-panel > button').attr('disabled', 'disabled');
    $('#reorder-form-btm > button').attr('disabled', 'disabled');
  },
  onUpdateSuccess: function (data, status, xhr) {
    $('.header-icon__badge').load(
      '/umbraco/surface/basketsurface/getbasketitemcount'
    );
    $('.js-readd-basket .loader').hide();
    $('html, body').animate(
      {
        scrollTop: 0
      },
      200
    );
    popup.show(
      'Available items added to your basket',
      popup.types.positive,
      popup.icons.positive
    );
  },
  onUpdateError: function (xhr, status, error) {}
};

window.BuyItAgain = {
  onBegin: function (data, status, xhr) {
    // console.log('begin',data);
    $(this).find('button').attr('disabled', 'disabled');
  },
  onUpdateSuccess: function (data, status, xhr) {
    $('.header-icon__badge').load(
      '/umbraco/surface/basketsurface/getbasketitemcount'
    );
    popup.show(
      'Product has been added to your basket',
      popup.types.positive,
      popup.icons.positive
    );
  },
  onUpdateError: function (xhr, status, error) {
    console.log('error', error);
  }
};
