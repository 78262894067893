// manually exported enums
export enum OneClickDefaultPaymentType {
  None = 0,
  Card = 1,
  Account = 2,
  Paypal = 3
}

export enum TransactionalCurrency {
  None = 0,
  GBP = 1,
  EUR = 2,
  AED = 3
}

export enum WebFMSSystem {
  None = 0,
  SIMS = 1,
  PSFinancials = 2,
  Civica = 3,
  Oracle = 4
}

export enum OrderStatus {
  None = 0,
  Created = 1,
  ProcessedInSAP = 2,
  ReadyToExport = 3,
  Exported = 4,
  PendingApproval = 5,
  Reject = 6,
  Cancelled = 7,
  ExportedToThirdParty = 8
}

export interface OrderAuditEntry {
  orderID: number;
  userName: string;
  establishmentName: string;
  orderLineCount: number;
  orderSubTotal: number;
  orderTotal: number;
  auditMessage: string;
  dateCreated: Date;
}

export enum DeliveryType {
  Standard = 0,
  NextDay = 1,
  Pre9AmDelivery = 2,
  Pre10AmDelivery = 3,
  AmDelivery = 4,
  PmDelivery = 5,
  DelayDelivery = 6
}

export enum OrderOrigin {
  Website,
  WebFMS,
  WebFlow
}

export enum PaymentType {
  /**The none */
  None,
  /**The payment on card */
  PaymentOnCard,
  /**The payment on account */
  PaymentOnAccount,
  /**The pay pal */
  PayPal,
  /**The sims */
  WebFMS,
  /**The web flow */
  WebFlow
}

export enum OrderTypeEnum {
  Order = 1,
  Quotation = 2 // For Quote history at this point
}

export enum Supplier {
  Findel = 1,
  SpecialistCrafts = 2
}

export enum ReturnReasonCode {
  Default = 'Reason for return',
  ProductNotAsExpected = 'Products is not as expected/advertised',
  ReceivedTooMany = 'Received too many',
  ReceivedDamaged = 'Received damaged',
  FaultyProduct = 'Product is faulty',
  NotRequired = 'No longer required',
  Other = 'Other'
}

export enum OfferTypes {
  ExtraFree = 'Extra Free',
  FreeProduct = 'Free Product'
}

export enum PageLocation {
  PLP,
  YMAL
}
